import React from 'react';

const RefundCancellationPolicy = () => {
  return (
    <div className="container company my-5 py-5 text-white">
      <h1 className="text-center">Refund and Cancellation Policy</h1>
      <div className="col-lg-8 mx-auto">
        <p>
          <strong>1. Digital Products</strong><br />
          All products provided by our platform are digital in nature, including but not limited to certificate.
        </p>
        
        <p>
          <strong>2. No Refund Policy</strong><br />
          As our products are digital and can be accessed immediately after purchase, <strong>all sales are final</strong>. We cannot provide refunds for any product once the transaction is completed, whether it has been downloaded, accessed, or consumed.
        </p>
        
        <p>
          <strong>3. Cancellation Policy</strong><br />
          Once a purchase is confirmed, <strong>cancellations are not permitted</strong>. Please ensure that you review your order carefully before making a purchase, as we do not accept any cancellations once the product is delivered.
        </p>
        
        <p>
          <strong>4. Exceptions</strong><br />
          In the unlikely event of a technical issue that prevents you from accessing your digital product, we will work with you to ensure that you receive your purchase. If the problem persists and cannot be resolved, we will provide support and make every reasonable effort to deliver the product in a usable format.
        </p>
        
        <p>
          <strong>5. Contact Us</strong><br />
          If you encounter any issues with your purchase, please do not hesitate to contact our support team at <a href="mailto:skilbudys@gmail.com">skilbudys@gmail.com</a>. We are committed to resolving any technical difficulties and ensuring that you receive the digital products you have purchased.
        </p>
      </div>
    </div>
  );
};

export default RefundCancellationPolicy;
