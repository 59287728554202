import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const CourseInquiryForm = () => {
    const navigate = useNavigate();
  const [course, setCourse] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    city: '',
    gender: '',
    course: '',
    headline: ''
  });

  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const courseId = queryParams.get('courseId');

    // Simulated course data
    const courses = [
      { id: 1, title: 'Frontend Development', description: 'Learn the latest in frontend technologies...', image: 'https://img.freepik.com/free-vector/frontend-developer-typographic-header-website-interface-design-improvement-web-page-programming-coding-testing-it-profession-isolated-flat-vector-illustration_613284-304.jpg?size=626&ext=jpg&ga=GA1.1.571894306.1703328841&semt=ais_hybrid' },
      { id: 2, title: 'Full Stack Development', description: 'Become a full stack developer...', image: 'https://img.freepik.com/free-vector/single-page-application-abstract-concept-illustration-spa-web-page-web-development-trend-app-inside-browser-dynamically-rewriting-page-responsive-website-creation_335657-899.jpg?ga=GA1.1.571894306.1703328841&semt=ais_hybrid' },
      { id: 3, title: 'Mobile App Development', description: 'Build mobile apps...', image: 'https://img.freepik.com/free-vector/mobile-ux-concept-illustration_114360-4536.jpg?ga=GA1.1.571894306.1703328841&semt=ais_hybrid' },
      { id: 4, title: 'Backend Development', description: 'Master server-side technologies...', image: 'https://img.freepik.com/free-vector/back-end-developer-typographic-header-software-development-process-website-interface-design-improvement-programming-coding-it-profession-isolated-flat-vector-illustration_613284-521.jpg?ga=GA1.1.571894306.1703328841&semt=ais_hybrid' },
    ];

    const selectedCourse = courses.find(c => c.id === parseInt(courseId));
    setCourse(selectedCourse || { title: '' });
    setFormData(prevData => ({
      ...prevData,
      course: selectedCourse ? selectedCourse.title : ''
    }));
  }, [location.search]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/inquiry`, {
        ...formData,
        courseId: course ? course.id : null
      });
      alert(res.data.message);
      setFormData({
        name: '',
        email: '',
        phone: '',
        city: '',
        gender: '',
      
        headline: ''
      })
    } catch (error) {
      console.error('There was an error submitting the inquiry!', error);
    }
  };

  return (
    <div className="course-inquiry-form-container my-5">
      <h1>Course Inquiry Form</h1>
    
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name" className='form-label'>Name:</label>
          <input
            type="text"
            id="name"
            name="name"
            className='form-control'
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="email" className='form-label'>Email:</label>
          <input
            type="email"
            id="email"
            name="email"
            className='form-control'
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="phone" className='form-label'>Phone:</label>
          <input
            type="tel"
            id="phone"
            name="phone"
            className='form-control'
            value={formData.phone}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="city" className='form-label'>City:</label>
          <input
            type="text"
            id="city"
            name="city"
            className='form-control'
            value={formData.city}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="gender" className='form-label'>Gender:</label>
          <select
            id="gender"
            name="gender"
            className='form-control'
            value={formData.gender}
            onChange={handleChange}
            required
          >
            <option value="">Select Gender</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
            <option value="Other">Other</option>
          </select>
        </div>

        

        <div className="form-group">
          <label htmlFor="inquiry" className='form-label'>Message:</label>
          <textarea
            id="headline"
            name="headline"
            className='form-control'
            value={formData.headline}
            onChange={handleChange}
            rows="4"
            required
          />
        </div>

        <button type="submit" className='btn-course '>Submit Inquiry</button>
      </form>
    </div>
  );
};

export default CourseInquiryForm;
