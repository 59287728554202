import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="container company my-5 py-5 text-white">
      <h1>Privacy Policy</h1>
    
      
      <p>Welcome to <strong>SkillBuddys</strong>. We respect your privacy and are committed to protecting the personal information you share with us. This Privacy Policy explains how we collect, use, and protect your information.</p>

      <h2>Information We Collect</h2>
      <ul>
        <li><strong>Personal Information:</strong> We collect personal information that you provide when you register on our website, make a purchase, or interact with our services (e.g., name, email, phone number, payment information).</li>
        <li><strong>Usage Data:</strong> We automatically collect data about your interactions with our website, such as IP addresses, browser type, pages visited, and usage patterns.</li>
        <li><strong>Cookies:</strong> We use cookies to enhance your experience on our website by remembering your preferences and customizing content.</li>
      </ul>

      <h2>How We Use Your Information</h2>
      <p>We use your information to:</p>
      <ul>
        <li>Provide and improve our services.</li>
        <li>Communicate with you (e.g., updates, promotional offers).</li>
        <li>Process transactions.</li>
        <li>Comply with legal obligations.</li>
      </ul>

      <h2>Data Protection</h2>
      <p>We implement security measures to protect your information against unauthorized access, alteration, or disclosure. However, no method of data transmission over the internet is 100% secure.</p>

      <h2>Third-Party Sharing</h2>
      <p>We do not sell, trade, or rent your personal information to others. However, we may share data with trusted third-party partners to help us operate our website, conduct our business, or serve you (e.g., payment processors).</p>

      <h2>Your Rights</h2>
      <p>You have the right to access, correct, or delete your personal information. You may also opt out of receiving marketing communications from us at any time.</p>

      <h2>Changes to This Policy</h2>
      <p>We may update this Privacy Policy occasionally. We encourage you to review this page regularly to stay informed about how we are protecting your information.</p>

       <h2>Contact Us</h2>
       <p>If you have any questions about this Privacy Policy, please contact us at 
       <a href="mailto:skilbudys@gmail.com">skilbudys@gmail.com</a></p> 
    </div>
  );
};

export default PrivacyPolicy;
