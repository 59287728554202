import React from 'react';

const AboutSkillBuddys = () => {
  
  return (
    <div className="py-5">
      <div className="container py-5 text-light">
        <div className="row">
          <h2 className="display-4 pb-4">
            About <span className="text-warning">SkillBuddys</span>
          </h2>
        
        </div>

        <div className="row align-items-center justify-content-between">

          <div className="col-lg-6">
            <div className="row">
            <div className="col-lg-12">
                <div className="rounded-circle bg-warning d-flex justify-content-center align-items-center" style={{ width: "65px", height: "65px" }}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#fff" className="bi bi-chat-left-quote" viewBox="0 0 16 16">
                    <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H4.414A2 2 0 0 0 3 11.586l-2 2V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12.793a.5.5 0 0 0 .854.353l2.853-2.853A1 1 0 0 1 4.414 12H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                    <path d="M7.066 4.76a.5.5 0 0 1 .347.933l-.797.296-.208.832a.5.5 0 0 1-.962-.246l.25-1a.5.5 0 0 1 .316-.338l1-.375zm3 0a.5.5 0 0 1 .347.933l-.797.296-.208.832a.5.5 0 0 1-.962-.246l.25-1a.5.5 0 0 1 .316-.338l1-.375z" />
                  </svg>
                </div>
                <h4 className="pb-1 pt-4">About Us</h4>
                <p className="lead">
                At SkillBuddys, we believe in empowering aspiring professionals to elevate their careers with industry-recognized certifications. Our mission is simple: to help you enhance your skills and stand out in the competitive job market with our specialized UI/UX certification programs.                </p>
              </div>
           
              <div className="col-lg-12">
                <div className="rounded-circle bg-warning d-flex justify-content-center align-items-center" style={{ width: "65px", height: "65px" }}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#fff" className="bi bi-archive" viewBox="0 0 16 16">
                    <path d="M0 2a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1v7.5a2.5 2.5 0 0 1-2.5 2.5h-9A2.5 2.5 0 0 1 1 12.5V5a1 1 0 0 1-1-1V2zm2 3v7.5A1.5 1.5 0 0 0 3.5 14h9a1.5 1.5 0 0 0 1.5-1.5V5H2zm13-3H1v2h14V2zM5 7.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z" />
                  </svg>
                </div>
                <h4 className="pb-1 pt-4">Who We Are</h4>
                <p className="lead">
                SkillBuddys is a forward-thinking platform dedicated to providing high-quality certification  tailored to UI/UX design. Whether you're just beginning your journey in design or looking to boost your credentials, our certifications are designed to help you succeed. We bring together industry experts, curated content, and a flexible learning environment that allows you to gain practical knowledge and a certificate that speaks volumes about your skills.
                </p>
              </div>
           
            
            </div>
          </div>
          <div className="col-lg-6 px-4">
            <img
              src="https://img.freepik.com/free-vector/online-certification-concept_52683-39854.jpg?t=st=1725356364~exp=1725359964~hmac=43e368c92e8f98da0c6e6ecd4f0acb36edba1522ccc1c72a25ebf6ec2ae0bcef&w=740"
              className="img-fluid rounded shadow"
              alt="SkillBuddys About"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSkillBuddys;
