import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Entry from './component/Entry';
import SalaryReport from './component/Stats';
import Quiz from './component/Quiz';
import Action from './component/Action';
import Certificate from './component/Certificate';
import CertificateGenerator from './component/Certificataions';
import Landing from './component/LadingPage';
import Navbar from './layout/Navbar';
import CertificateVerification from './component/CertificateVerification';
import AboutUs from './component/About';
import Footer from './layout/Footer';
import PrivacyPolicy from './component/PrivacyPolicy';
import TermsAndConditions from './component/TermsAndConditions';
import ContactUs from './component/ContactUs';
import RefundCancellationPolicy from './component/RefundCancellationPolicy';
import ScrollToTop from './ScrollToTop';
import CourseInquiryForm from './component/CourseInquiryForm';
import Explore from './component/Explore';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Navbar/>
        <ScrollToTop/>
        <Routes>
          <Route path='/' element={<Landing />} />
          <Route path='/about-us' element={<AboutUs/>} />
          <Route path='/get-started' element={<Entry />} />
          <Route path='/our-learner' element={<SalaryReport />} />
          <Route path='/quiz' element={<Quiz />} />
          <Route path='/certification' element={<Action />} />
          <Route path='/certificate/' element={<Certificate />} />
          <Route path='/download-certificate/:userId' element={<CertificateGenerator />} />
          <Route path="/verify-certificate" element={<CertificateVerification />} />
          <Route path='/inquiry' element={<CourseInquiryForm/>} />
          <Route path='/our-course' element={<Explore/>} />


          <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
          <Route path="/terms-and-conditions" element={<TermsAndConditions/>} />
          <Route path='/contact' element={<ContactUs/>} />
          <Route path='/refund' element={<RefundCancellationPolicy/>} />

        </Routes>
        <Footer/>
      </BrowserRouter>
    </div>
  );
}

export default App;
