import React from 'react';
import { FaFacebook, FaTwitter, FaLinkedin, FaInstagram } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-dark text-white pt-5 pb-3">
      <div className="container">
        <div className="row">
          {/* About Us Section */}
          <div className="col-md-3 col-sm-12">
            <h5>About Us</h5>
            <p>
            At SkillBuddys, we believe in empowering aspiring professionals to elevate their careers with industry-recognized certifications. Our mission is simple: to help you enhance your skills and stand out in the competitive job market with our specialized UI/UX certification programs.
            </p>
            <div className="social-icons">
              <a href="https://www.facebook.com/people/Skill-Buddys/61566025453208/
" target="_blank" rel="noreferrer" className="text-white me-3">
                <FaFacebook />
              </a>
             
              {/* <a href="https://linkedin.com" target="_blank" rel="noreferrer" className="text-white me-3">
                <FaLinkedin />
              </a> */}
              <a href="https://www.instagram.com/skillbuddys" target="_blank" rel="noreferrer" className="text-white">
                <FaInstagram />
              </a>
            </div>
          </div>

          {/* Links Section */}
          <div className="col-md-3 col-sm-12">
            <h5>Quick Links</h5>
            <ul className="list-unstyled">
              <li><Link to="/" className="text-white">Home</Link></li>
              <li><Link to="/about-us" className="text-white">About Us</Link></li>
              <li><Link to="/verify-certificate" className="text-white">Certificate</Link></li>
              <li><Link to="/our-course" className="text-white">Our Course</Link></li>
            </ul>
          </div>

          <div className="col-md-3 col-sm-12">
            <h5>Company</h5>
            <ul className="list-unstyled">
           
              <li><Link to="/privacy-policy" className="text-white">Privacy Policy</Link></li>
              <li><Link to="/terms-and-conditions" className="text-white">Terms & Conditions</Link></li>
              <li><Link to="/refund" className="text-white">Refund & Cancellation</Link></li>
              <li><Link to="/contact" className="text-white">Contact Us</Link></li>
            </ul>
          </div>

          {/* Newsletter Section */}
          <div className="col-md-3 col-sm-12">
            <h5>Newsletter</h5>
            <p>Subscribe to our newsletter for the latest updates and offers.</p>
            <form className="d-flex">
              <input
                type="email"
                className="form-control me-2"
                placeholder="Enter your email"
                required
              />
              <button type="submit" className="btn btn-success">
                Subscribe
              </button>
            </form>
          </div>
        </div>

        {/* Footer Bottom */}
        <div className="row mt-4">
          <div className="col text-center">
            <p className="mb-0">© 2024 SkillBuddys. All rights reserved. | <a href="mailto:skilbudys@gmail.com">skilbudys@gmail.com</a>  </p>
             
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
