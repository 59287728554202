import React from "react";
import "./../Certificate.css"; // Assuming styles are in a separate CSS file

const Certificate = () => {
  const username = localStorage.getItem('username') || "John Doe";
  const userId = localStorage.getItem('userId') || "ID12345";
  const score = localStorage.getItem('score') || 9;

  // Get current date and format it as "MM/DD/YYYY"
  const currentDate = new Date();
  const formattedDate = currentDate.toLocaleDateString('en-US', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });

  // Function to format the Certificate ID with masking
  const formatCertificateId = (id) => {
    if (!id) return "[ID]";
    const firstTwo = id.slice(0, 2);
    const lastTwo = id.slice(-2);
    const masked = '*'.repeat(id.length - 4);
    return `${firstTwo}${masked}${lastTwo}`;
  };

  return (
    <div className="certificate-container">
      <div className="certificate-frame">
        <div className="certificate-content">
          {/* Header */}
          <div className="header">
            <img src="https://cb.arrivalsolutions.in/logo.png" alt="Logo" />
          </div>
          <h1>Certificate of Achievement</h1>
          <p>This certificate is presented to</p>
          <h2 className="name">{username}</h2>
          <p>
            Has Successfully Completed 
            the quiz by answering 10 expertly crafted questions designed to assess proficiency in
            User Interface (UI) and User Experience (UX) Design.
          </p>
          <p>
            <b>Awarded On: </b> {formattedDate} <br />
            <b>Score: </b> {score} / 10
          </p>

          {/* Signature Section */}
          <div className="signature">
            <div>
              <span className="signature-name">Signature</span>
              <div className="signature-line"></div>
              <span className="signature-name"><b>Co - Founder</b> <br /> Skill Buddys</span>
            </div>
            <div>
              <img src="https://cb.arrivalsolutions.in/stamp-CB.png" alt="Stamp" width="120" />
            </div>
            <div>
              <span className="signature-name">Certificate ID</span>
              <div className="signature-line"></div>
              <span className="signature-name">{formatCertificateId(userId)}</span>
            </div>
          </div>

          {/* Footer */}
          <div className="footer">
            <p>info@skillbuddys.online | www.skillbuddys.online</p>
          </div>
        </div>
        <div className="certificate-decoration"></div>
      </div>
    </div>
  );
};

export default Certificate;
