import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

const Quiz = () => {
    const [questions, setQuestions] = useState([]);  // Store quiz questions
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);  // Track current question
    const [selectedOptions, setSelectedOptions] = useState({});  // Track selected options for each question
    const [progress, setProgress] = useState(0);  // Track progress through the quiz
    const [score, setScore] = useState(0);  // Store score
    const [quizCompleted, setQuizCompleted] = useState(false);  // Track if quiz is completed
    const [timeLeft, setTimeLeft] = useState(30 * 60);  // 30 minutes in seconds for the quiz timer
    const navigate = useNavigate();  // React Router's navigation hook

    // Fetch quiz data from API
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/api/quizzes`)
            .then(response => {
                setQuestions(response.data);
            })
            .catch(error => {
                console.error("There was an error fetching the quiz data!", error);
            });
    }, []);

    // Timer countdown logic
    useEffect(() => {
        if (timeLeft <= 0) {
            setQuizCompleted(true);
        }

        const timer = setInterval(() => {
            setTimeLeft(prevTime => prevTime - 1);
        }, 1000);

        return () => clearInterval(timer);
    }, [timeLeft]);

    // Handle option selection
    const handleOptionSelect = (option) => {
        const newSelectedOptions = {
            ...selectedOptions,
            [currentQuestionIndex]: option
        };
        setSelectedOptions(newSelectedOptions);
    };

    // Calculate the score after the quiz is completed
    const calculateScore = () => {
        let calculatedScore = 0;
        questions.forEach((question, index) => {
            if (selectedOptions[index]?.trim() === question.answer.trim()) {
                calculatedScore++;
            }
        });
        setScore(calculatedScore);
    };

    // Handle next question logic
    const handleNextQuestion = () => {
        const nextQuestionIndex = currentQuestionIndex + 1;
        if (nextQuestionIndex < questions.length) {
            setCurrentQuestionIndex(nextQuestionIndex);
            setProgress(((nextQuestionIndex + 1) / questions.length) * 100);
        } else {
            setQuizCompleted(true);
            calculateScore();
            setProgress(100);
        }
    };

    // Handle previous question logic
    const handlePreviousQuestion = () => {
        const prevQuestionIndex = currentQuestionIndex - 1;
        if (prevQuestionIndex >= 0) {
            setCurrentQuestionIndex(prevQuestionIndex);
            setProgress(((prevQuestionIndex + 1) / questions.length) * 100);
        }
    };

    // Format time left for display (mm:ss)
    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
    };

    // Display quiz completion message
    if (quizCompleted) {
        // Store the score in localStorage
        localStorage.setItem('score', score);

        return (
            <div className='body'>
                <div className='container wrappers mt-sm-5'>
                    <div className="quiz-container mt-sm-5 text-center">
                        <h2>Quiz Completed!</h2>
                        {score >= 6 ? (
                            <>
                                <h3>Congratulations {localStorage.getItem('username')}, You Qualified!</h3>
                                <button
                                    className="btn btn-success mt-4 w-100"
                                    onClick={() => navigate('/certificate')}
                                >
                                    Get Certificate
                                </button>
                            </>
                        ) : (
                            <>
                                <h3>Sorry {localStorage.getItem('username')}, You Did Not Qualify</h3>
                                <button
                                    className="btn btn-danger mt-4 w-100"
                                    onClick={() => window.location.reload()}
                                >
                                    Retake Test
                                </button>
                            </>
                        )}
                    </div>
                </div>
            </div>
        );
    }

    // If questions haven't loaded yet
    if (questions.length === 0) {
        return <div>Loading...</div>;
    }

    // Get current question and selected option
    const currentQuestion = questions[currentQuestionIndex];
    const currentOption = selectedOptions[currentQuestionIndex] || '';

    return (
        <div className='body'>
            <div className='wrappers mt-sm-5'>
                <div className="quiz-container mt-sm-5">
                    {/* Timer */}
                    <div className="timer text-end">
                        <h5>Time Left: {formatTime(timeLeft)}</h5>
                    </div>

                    {/* Progress bar */}
                    <div className="progress-bar-custom mt-sm-5 mb-3">
                        <div className="progress">
                            <div className="progress-bar" role="progressbar" style={{ width: `${progress}%`, backgroundColor: '#ffcc00' }} aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                        <span className="mt-4">{currentQuestionIndex + 1}/{questions.length}</span>
                    </div>

                    <p className='text-left mt-4 pt-2'>86% of people who completed this quiz got hired</p>

                    {/* Question */}
                    <h3 className="mb-4 text-left">{currentQuestion.question}</h3>

                    {/* Options */}
                    <ul className="options-list text-start">
                        {currentQuestion.options.map((option, index) => (
                            <li
                                key={index}
                                className={`option-box ${currentOption === option ? 'selected' : ''}`}
                                onClick={() => handleOptionSelect(option)}
                            >
                                {option}
                            </li>
                        ))}
                    </ul>

                    {/* Navigation Buttons */}
                    <div className="d-flex justify-content-between mt-4">
                        <button 
                            className="btn btn-success px-4"
                            onClick={handlePreviousQuestion}
                            disabled={currentQuestionIndex === 0}
                        >
                            Previous
                        </button>
                        <button 
                            className="btn btn-success px-4"
                            onClick={handleNextQuestion}
                            disabled={!selectedOptions[currentQuestionIndex]}  // Disable if no option selected
                        >
                            {currentQuestionIndex === questions.length - 1 ? 'Finish' : 'Next'}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Quiz;
