import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const ContactUs = () => {
  const navigate = useNavigate();
  const [course, setCourse] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    subject: '',
    message: '',
  });


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/contact`, {
        ...formData,

      });
      alert(res.data.message);
      setFormData({
        name: '',
        email: '',
        phone: '',
        subject: '',
        message: '',
      })
    } catch (error) {
      console.error('There was an error submitting the inquiry!', error);
    }
  };

  return (
    <div className="course-inquiry-form-container my-5">
      <h1>Contact Us</h1>
      <p>We'd love to hear from you! Fill out the form below, and we will get in touch with you shortly.
      </p>
      <form onSubmit={handleSubmit}>

        <div className="form-group">

         
          <label htmlFor="name" className='form-label'>Name:</label>
          <input
            type="text"
            id="name"
            name="name"
            className='form-control'
            value={formData.name}
            onChange={handleChange}
            required
          />

        </div>

        <div className="form-group">
          <label htmlFor="email" className='form-label'>Email:</label>
          <input
            type="email"
            id="email"
            name="email"
            className='form-control'
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="phone" className='form-label'>Phone:</label>
          <input
            type="tel"
            id="phone"
            name="phone"
            className='form-control'
            value={formData.phone}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="subject" className='form-label'>Subject:</label>
          <input
            type="text"
            id="subject"
            name="subject"
            className='form-control'
            value={formData.subject}
            onChange={handleChange}
            required
          />
        </div>



        <div className="form-group">
          <label htmlFor="inquiry" className='form-label'>Message:</label>
          <textarea
            id="message"
            name="message"
            className='form-control'
            value={formData.message}
            onChange={handleChange}
            rows="4"
            required
          />
        </div>

        <button type="submit" className='btn-course '>Submit </button>
      </form>
    </div>
  );
};

export default ContactUs;
