import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useHistory hook for programmatic navigation

const Explore = () => {
  const Navigate = useNavigate();
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [showForm, setShowForm] = useState(false);

  // Sample course data
  const courses = [
    { id: 1, title: 'UI/UX', description: 'Learn the essentials of UI/UX design with Figma. Create wireframes, prototypes, and interactive designs, collaborating in real-time with powerful design tools tailored for modern web and mobile interfaces.', image: 'https://img.freepik.com/free-vector/gradient-ui-ux-background_23-2149051555.jpg?size=626&ext=jpg&ga=GA1.1.918187449.1718384370&semt=ais_hybrid' },

    { id: 2, title: 'Frontend Development', description: 'Master frontend development using React and Next.js. Build dynamic, fast, and SEO-friendly web applications, harnessing the power of JavaScript and modern tools to create responsive user interfaces.', image: 'https://img.freepik.com/free-vector/frontend-developer-typographic-header-website-interface-design-improvement-web-page-programming-coding-testing-it-profession-isolated-flat-vector-illustration_613284-304.jpg?size=626&ext=jpg&ga=GA1.1.571894306.1703328841&semt=ais_hybrid' },

    { id: 3, title: 'Full Stack Development', description: 'come a full-stack developer by mastering the MERN stack (MongoDB, Express, React, Node.js). Learn to build scalable web applications, handle databases, and integrate backend and frontend seamlessly.', image: 'https://img.freepik.com/free-vector/single-page-application-abstract-concept-illustration-spa-web-page-web-development-trend-app-inside-browser-dynamically-rewriting-page-responsive-website-creation_335657-899.jpg?ga=GA1.1.571894306.1703328841&semt=ais_hybrid' },

    { id: 4, title: 'Mobile App Development', description: 'evelop cross-platform mobile applications using React Native. Learn to build responsive, high-performance apps for iOS and Android, leveraging JavaScript and React principles.', image: 'https://img.freepik.com/free-vector/mobile-ux-concept-illustration_114360-4536.jpg?ga=GA1.1.571894306.1703328841&semt=ais_hybrid' },
    
    { id: 5, title: 'Backend Development', description: ' Develop cross-platform mobile applications using React Native. Learn to build responsive, high-performance apps for iOS and Android, leveraging JavaScript and React principles.', image: 'https://img.freepik.com/free-vector/back-end-developer-typographic-header-software-development-process-website-interface-design-improvement-programming-coding-it-profession-isolated-flat-vector-illustration_613284-521.jpg?ga=GA1.1.571894306.1703328841&semt=ais_hybrid' },
  ];

  const handleInquiryClick = (course) => {
    // Navigate to the inquiry page with the course ID as a URL parameter
    Navigate(`/inquiry?courseId=${course.id}`);
  };

  return (
    <div className="explore-container my-3">
      <h2 className='text-light mb-3'>Explore Our Courses</h2>
      <div class="row row-cols-1 row-cols-md-3 g-4">

        {courses.map(course => (
          <div class="col">
            <div class="card h-100">
              <img src={course.image} alt={course.title} className="course-image" />
              <div class="card-body">
                <h5 class="card-title">{course.title}</h5>
                <p class="card-text text-start">{course.description}</p>
                <button
                  className="explore-button"
                  onClick={() => handleInquiryClick(course)}
                >
                  Inquiry Now
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>

    </div>
  );
};

export default Explore;
