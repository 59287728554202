import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Entry = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [errors, setErrors] = useState({});
    const [showModal, setShowModal] = useState(false);

    const navigate = useNavigate();

    // Regular expression for validation
    const nameRegex = /^[a-zA-Z\s]*$/;
    const phoneRegex = /^[0-9]{10}$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Real-time validation for inputs
    const validateName = (name) => {
        if (!nameRegex.test(name)) {
            setErrors(prev => ({ ...prev, name: 'Name should only contain letters and spaces.' }));
        } else {
            setErrors(prev => ({ ...prev, name: '' }));
        }
        setName(name);
    };

    const validatePhone = (phone) => {
        if (!phoneRegex.test(phone)) {
            setErrors(prev => ({ ...prev, phone: 'Phone number must be exactly 10 digits.' }));
        } else {
            setErrors(prev => ({ ...prev, phone: '' }));
        }
        setPhone(phone);
    };

    const validateEmail = (email) => {
        if (!emailRegex.test(email)) {
            setErrors(prev => ({ ...prev, email: 'Please enter a valid email address.' }));
        } else {
            setErrors(prev => ({ ...prev, email: '' }));
        }
        setEmail(email);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Double-check validation before submission
        if (!errors.name && !errors.phone && !errors.email) {
            try {
                const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/register`, {
                    name,
                    email,
                    phone
                });

                const username = res.data.name;
                const userId = res.data._id;
                const mail = res.data.email;
                const phoneNo = res.data.phone;

                localStorage.setItem('userId', userId);
                localStorage.setItem('username', username);
                localStorage.setItem('mail', mail);
                localStorage.setItem('phoneNo', phoneNo);

                setShowModal(true); // Show the modal after successful submission
            } catch (error) {
                console.log(error);
            }
        } else {
            alert("Please fix the validation errors before submitting.");
        }
    };

    const handleModalClose = () => {
        setShowModal(false);
        navigate('/get-started');
    };

    const handleModalNext = () => {
        setShowModal(false);
        navigate('/quiz');
    };

    return (
        <div className='body'>
            <div className='wrappers'>
                <div className="quiz-container">
                    <form onSubmit={handleSubmit}>
                        <div className='mb-3'>
                            <label>Full Name</label>
                            <input
                                className='form-control'
                                value={name}
                                onChange={(e) => validateName(e.target.value)}
                                required
                            />
                            {errors.name && <small className='text-danger'>{errors.name}</small>}
                        </div>
                        <div className='mb-3'>
                            <label>Phone Number</label>
                            <input
                                className='form-control'
                                value={phone}
                                onChange={(e) => validatePhone(e.target.value)}
                                required
                            />
                            {errors.phone && <small className='text-danger'>{errors.phone}</small>}
                        </div>
                        <div className='mb-3'>
                            <label>Email Address</label>
                            <input
                                className='form-control'
                                value={email}
                                onChange={(e) => validateEmail(e.target.value)}
                                required
                            />
                            {errors.email && <small className='text-danger'>{errors.email}</small>}
                        </div>
                        <div className='mb-3'>
                            <button type="submit" className="btn btn-success btn-lg w-100">Submit</button>
                        </div>
                    </form>
                </div>
            </div>

            {/* Modal */}
            {/* Modal */}
{showModal && (
    <div className="modal show d-block" tabIndex="-1" role="dialog">
        <div className="modal-dialog" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" style={{ color: '#ff5722' }}>Your UI/UX Test Journey Started</h5>
                    <button type="button" className="btn-close" onClick={handleModalClose}></button>
                </div>
                <div className="modal-body">
                    <p className="text-dark">
                        <ul className="text-start">
                            <li>Timing: The exam must be completed within the allocated time.</li>
                            <li>Browser Tab Changes: Changing browser tabs will be monitored.</li>
                        </ul>
                    </p>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-success" onClick={handleModalNext}>Go to Quiz</button>
                </div>
            </div>
        </div>
    </div>
)}

        </div>
    );
};

export default Entry;
