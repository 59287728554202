import React from "react";
import { Link } from "react-router-dom";
import certificate from './../img/certicates.png'
const Landing = () => {

    return (
        <div class="container col-xxl-9 px-4 py-5 mt-5">
        <div class="row    align-items-center g-5 ">

          <div class="col-12 col-sm-12 col-lg-6">
            <img src={certificate} class="d-block mx-lg-auto img-fluid" alt="Bootstrap Themes" width="700" height="500" loading="lazy" />
              <div class="col-12 text-white boost-text">
                <p className="lead"> <strong>Boost</strong> your chances by <strong>70%</strong>. Showcase this certificate to recruiters and stand out from the competition.</p>
            </div>
          </div>
          
          <div class="col-lg-6">
            <h3 class="fw-bold   lh-1 mb-3 landing-heading">Get Certified Now !</h3>
            <p class="lead">
            Test your <strong>UI/UX expertise</strong> with our <strong>10 challenging questions</strong> .
            Showcase your <strong>skills</strong> and claim your <strong>certificate</strong> today!
            </p>
            <div class="d-grid gap-2 d-md-flex justify-content-md-start">
              <Link type="button" class="btn btn-landing  W-100"
              to="/get-started">
                Collect Your Certificate
              </Link>
              
            </div>
          </div>
        </div>
      </div>
    )
}
export default Landing;