import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Certificates from './Certificataions'; // Ensure this component is implemented
import Explore from './Explore'; // Ensure this component is implemented

const Certificate = () => {
  const [faqs, setFaqs] = useState([]);
  const [certificateUrl, setCertificateUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false); // Loader state

  const username = localStorage.getItem('username') || 'Your Name';
  const userId = localStorage.getItem('userId');
  const score = localStorage.getItem('score') || 0;
  const phoneNo = localStorage.getItem('phoneNo');
  const mail = localStorage.getItem('mail');

  // Masked Certificate ID
  const formatCertificateId = (id) => {
    if (!id) return "[ID]";
    const firstTwo = id.slice(0, 2);
    const lastTwo = id.slice(-2);
    const masked = '*'.repeat(id.length - 4);
    return `${firstTwo}${masked}${lastTwo}`;
  };

  // Fetch FAQs and certificate data when component loads
  useEffect(() => {
    if (userId) {
      // Fetch FAQs
      axios.get(`${process.env.REACT_APP_API_URL}/api/faq`)
        .then(response => setFaqs(response.data))
        .catch(error => console.error('Error fetching FAQs:', error));

      // Check if the user already has a certificate
      axios.get(`${process.env.REACT_APP_API_URL}/api/certificate/${userId}`)
        .then(response => {
          if (response.data.certificateUrl) {
            setCertificateUrl(response.data.certificateUrl);
          }
        })
        .catch(error => console.error('Error fetching certificate:', error));
    }
  }, [userId]);

  // Payment handling with Razorpay
  const handlePayment = async () => {
    if (!score) {
      alert('Score is required to proceed with payment.');
      return;
    }

    try {
      setIsLoading(true); // Start loader

      // Create a new order on the server
      const { data: order } = await axios.post(`${process.env.REACT_APP_API_URL}/api/create-order`, {
        amount: 119, // INR amount
        currency: 'INR',
        userId,
      });

      // Razorpay configuration
      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY_ID,
        amount: order.amount,
        currency: order.currency,
        name: 'Skill Buddys',
        description: 'Certificate Payment',
        order_id: order.id,
        handler: async (response) => {
          try {
            // Verify the payment on the server
            const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/api/verify-payment`, {
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_order_id: response.razorpay_order_id,
              razorpay_signature: response.razorpay_signature,
              userId,
            });

            // Generate the certificate after payment
            const certificateResponse = await axios.post(`${process.env.REACT_APP_API_URL}/api/generate-certificate`, {
              userId,
              score,
            });

            const certificateUrl = `${process.env.REACT_APP_API_URL}${certificateResponse.data.certificateUrl}`;
            setCertificateUrl(certificateUrl);

            alert('Payment Successful! Your certificate is ready to view and download.');
          } catch (error) {
            console.error('Payment verification or certificate generation failed:', error);
            alert('There was an error processing your payment. Please try again.');
          } finally {
            setIsLoading(false); // Stop loader
          }
        },
        prefill: {
          name: username,
          email: mail,
          contact: phoneNo,
        },
        theme: {
          color: '#3399cc',
        },
      };

      const razorpay = new window.Razorpay(options);
      razorpay.open();
    } catch (error) {
      console.error('Error initiating payment:', error);
      alert('There was an error initiating the payment. Please try again.');
      setIsLoading(false); // Stop loader on failure
    }
  };

  // Download certificate after payment
  const downloadCertificate = () => {
    const link = document.createElement('a');
    link.href = certificateUrl;
    link.setAttribute('download', 'certificate.pdf'); // Set filename here
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); // Clean up after download
  };

  return (
    <div className='container col-lg-8 mx-auto my-5'>
      <div className='topbar'>
        <h1>Congrats {username}, <br /><span>You've Passed</span></h1>
      </div>

      <div className='certificate'>
        <div className='container col-lg-10 mx-auto py-2'>
          <h2>
            Unlock Instant Certificate to View Score and Prove You're in the Top <span>10% of UI & UX Candidates</span>
          </h2>

          {/* Conditionally render the certificate or the static image */}
          {certificateUrl ? (
            <>
              <div className='certificate-frame my-3'>
                <iframe 
                  src={certificateUrl} 
                  title="Certificate Preview" 
                  style={{ width: '100%', height: '500px', border: 'none' }}>
                </iframe>
              </div>
              <button onClick={downloadCertificate} className='btn btn-certificate my-3'>
                Download Certificate
              </button>
            </>
          ) : (
            <>
              {/* Show preview certificate */}
              <div className='certificate-preview'>
                <h3>Name: {username}</h3>
                <h4>Score: {score} / 10</h4>
                <h4>Certificate ID: {formatCertificateId(userId)}</h4>
              </div>
              <button className='btn btn-certificate my-3 text-dark' onClick={handlePayment} disabled={isLoading}>
                {isLoading ? 'Processing Payment...' : 'Get Instant Certificate'}
              </button>

              <p className='lead my-3'>For Just &#x20B9; 119 <del className='text-danger'>&#x20B9; 599</del></p>

              {isLoading && (
                <div className="text-center my-3 text-light">
                  <p>Please wait, your payment is being processed. Do not refresh the page...</p>
                  <div className="spinner-border text-primary" role="status">
                    <span className="sr-only"></span>
                  </div>
                </div>
              )}
            </>
          )}
          
          <h5 className='text-center'>Explore Additional Features</h5>
          <p className='text-center'><i className="bi bi-chevron-down"></i></p>
        </div>
      </div>

      <div className='faq-card'>
        <Explore/>
      </div>

      <div className='faq-card'>
        <div className="accordion accordion-flush" id="accordionFlushExample">
          {faqs.map((faq, index) => (
            <div className="accordion-item" key={faq._id}>
              <h2 className="accordion-header" id={`flush-heading${index}`}>
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#flush-collapse${index}`} aria-expanded="false" aria-controls={`flush-collapse${index}`}>
                  {faq.question}
                </button>
              </h2>
              <div id={`flush-collapse${index}`} className="accordion-collapse collapse" aria-labelledby={`flush-heading${index}`} data-bs-parent="#accordionFlushExample">
                <div className="accordion-body">
                  {faq.answer}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Certificate;
