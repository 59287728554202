import React from 'react';

const TermsAndConditions = () => {
  return (
    <div className="container company my-5 py-5 text-white">
      <h1>Terms and Conditions</h1>
      
      <p>Welcome to <strong>SkillBuddys</strong>. These Terms and Conditions ("Terms") govern your use of our website and services. By using our website, you agree to comply with these Terms.</p>

      <h2>Account Registration</h2>
      <p>You may be required to create an account to access certain features of our services. You are responsible for maintaining the confidentiality of your account information and agree to accept responsibility for all activities under your account.</p>

      <h2>Prohibited Conduct</h2>
      <ul>
        <li>Use our services for any illegal activities.</li>
        <li>Violate any local, state, national, or international law.</li>
        <li>Submit false information or impersonate another person.</li>
        <li>Interfere with the operation of our website.</li>
      </ul>

      <h2>Intellectual Property</h2>
      <p>All content on our website (text, graphics, logos, images) is the property of <strong>[Your Company Name]</strong> and is protected by copyright and other laws. You may not reproduce or distribute any content without our prior written consent.</p>

      <h2>Disclaimer of Warranties</h2>
      <p>Our website and services are provided "as is" without warranties of any kind, either express or implied, including but not limited to the implied warranties of merchantability, fitness for a particular purpose, or non-infringement.</p>

      <h2>Limitation of Liability</h2>
      <p><strong>[Your Company Name]</strong> will not be liable for any damages arising from your use of our services, including but not limited to direct, indirect, incidental, punitive, and consequential damages.</p>

      <h2>Governing Law</h2>
      <p>These Terms are governed by and construed in accordance with the laws of [Your Jurisdiction]. Any legal action or proceeding arising under these Terms will be brought exclusively in the courts of [Your Jurisdiction].</p>

      <h2>Changes to Terms</h2>
      <p>We reserve the right to update or modify these Terms at any time. You are encouraged to review these Terms periodically for any changes.</p>

      <h2>Contact Us</h2>
      <p>If you have any questions regarding these Terms, please contact us at 
      <a href="mailto:skilbudys@gmail.com">skilbudys@gmail.com</a>.</p>
    </div>
  );
};

export default TermsAndConditions;
