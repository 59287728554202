import React, { useState } from 'react';
import axios from 'axios';

const VerifyCertificate = () => {
    const [userId, setUserId] = useState('');
    const [verificationResult, setVerificationResult] = useState(null);
    const [error, setError] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!userId) {
            setError('Certificate ID is required');
            return;
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/verify-certificate`, { userId });
            setVerificationResult(response.data);
            setError(null);
            console.log(response.data)
        } catch (err) {
            setVerificationResult(null);
            setError(err.response?.data?.message || 'An error occurred');
        }
    };

    return (
        <div className='container py-5 my-5'>
            <div className='col-xl-6 mx-auto py-5'>
                <h1 className='text-light mb-3'>Verify Certificate</h1>
                <form onSubmit={handleSubmit}>
                    <div className='mb-3'>
                       
                        <input
                            type="text"
                            id="userId"
                            className='form-control'
                            value={userId}
                            onChange={(e) => setUserId(e.target.value)}
                            placeholder='Enter certificate id'
                        />
                    </div>
                    <button type="submit" className='btn btn-success w-100'>Verify</button>
                </form>

                {error && <p style={{ color: 'red' }}>{error}</p>}

                {verificationResult && verificationResult.valid && verificationResult.userData && (
                    <div className=' my-4' style={{color : "#fff "}}>
                        {/* <h2>Certificate Verified Successfully!</h2> */}
                        <h2>{verificationResult.message}</h2>
                        <p><strong>Certificate ID:</strong> {userId}</p>
                        <p><strong>Name:</strong> {verificationResult.userData.name}</p>
                        <p><strong>Email:</strong> {verificationResult.userData.email}</p>
                        <p><strong>Score :</strong> {verificationResult.userData.score}</p>
                        <p>
  <strong>Issue Date:</strong> {new Date(verificationResult.userData.issueDate).toLocaleDateString('en-GB')}
</p>


                        <a
                            href={`http://localhost:5000${verificationResult.userData.certificateUrl}`}
                            download
                            target="_blank"
                            rel="noopener noreferrer"
                            className='btn btn-success'
                        >
                            View Certificate
                        </a>

                    </div>
                )}

                {verificationResult && !verificationResult.valid && (
                    <p style={{ color: 'red' }}>Certificate not found or is invalid.</p>
                )}
            </div>
        </div>
    );
};

export default VerifyCertificate;
